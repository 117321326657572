<template>
  <div class="regulartext">
    <p>Founded in 2008 by two college friends and later reinforced by a third after his stint in the AAA arena, Noumenon Games is a hermit game studio based in sweden that enjoys exploring game design.</p>
    <p>We also do a lot of outsourcing on the side, supporting other studios with programming, 3D animation and art, game design, and level design. Feel free to <router-link to="/contact">contact</router-link> us if you’re looking for extra people.</p>
  </div>
  <br>
  <div class="headertext">Sebastian Karlsson</div>
  <div class="regulartext">Main programming, game design</div>
  <br>
  <div class="headertext">Felix Eliasson</div>
  <div class="regulartext">Game, level and art design, additional programming</div>
  <br>
  <div class="headertext">Joel Astbo</div>
  <div class="regulartext">Animation, additional art and programming</div>
</template>
